import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Layout from "components/global/Layout";
import * as Scroll from 'react-scroll';

// Components
import GridCard from "components/creative-effectiveness/GridCard";
import VideoCard from "components/creative-effectiveness/VideoCard";
import VideoModal from "components/creative-effectiveness/VideoModal";
import LayoutContent from "components/page-builder/LayoutContent";

// Styles
import styles from "styles/pages/GridPage.module.scss";
import directoryStyles from "styles/pages/Directory.module.scss";
import shareScaleStyles from "styles/pages/ShareScale.module.scss";
import cardStyles from "styles/components/GridCard.module.scss";
import gsStyles from "styles/pages/feel-good-design/GettingStarted.module.scss";

// Functions
import {
	fetchShareAndSteal,
	fetchCEPage,
	processBannerImage,
	processVideoUrl,
	slugify
} from "helpers/functions";
import parse from "html-react-parser";
import { createPortal } from "react-dom";

// state
import { useRecoilState, useRecoilValue } from "recoil";
import { pageLoadedState, currentUserState } from "state/atoms";

// Interface
import { IfaceAllNewestResponse, IfaceCurrentUser } from "helpers/interfaces";

// Next
import Image from "next/image";

// Icons
import IconCalendar from "images/icon-calendar.svg";
import IconPlay from "images/icon-video-play-2.svg";
import { camelCase } from "lodash";

const Modal = (props: any) => {
	const [container] = React.useState(() => {
		// This will be executed only on the initial render
		// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
		return document.createElement('div');
	});

	React.useEffect(() => {
		container.classList.add('root-protal')
		document.body.appendChild(container)
		return () => {
			document.body.removeChild(container)
		}
	}, [])

	return createPortal(
		props.children,
		container,
	);
}

const Page = () => {
	const [pageLoaded, setPageLoaded] = useRecoilState(pageLoadedState);

	useEffect(() => {
		setTimeout(() => {
			setPageLoaded(true);
		}, 125);
	}, []);

	// Get slug from URL
	const router = useRouter();
	const { slug }: any = router.query;

	const [fourOhFour, setFourOhFour] = useState<boolean>(false);
	const [page, setPage] = useState<any>(null);
	const [pageTitle, setPageTitle] = useState<string>('');
	const [isDirectory, setIsDirectory] = useState<boolean>(false);
	const [sections, setSections] = useState<any>(null);
	const [isShare, setIsShare] = useState<any>(null);
    const [status, setStatus] = useState<any>(null);
	const currentUser: IfaceCurrentUser = useRecoilValue(currentUserState);
	const [isAdmin, setIsAdmin] = useState(false);

	const getPage = async () => {
        const data: any = await fetchCEPage(slug.join('/'));
		if (!data.error) {
			setPage(data);
			if (fourOhFour) {
				setFourOhFour(false);
			}
		} else {
			setFourOhFour(true);
		}
	};

	function toTitleCase(str: any) {
		return str.replace(/\w\S*/g, function (txt: any) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	useEffect(() => {
		if (slug !== undefined && slug.length > 0) {
			getPage();
		} else if (window) {
			const pathname: any = window.location.pathname.replace(/^\/|\/$/g, '');
			const pathArr: any = pathname.split('-');
			setPageTitle(toTitleCase(pathArr.join(' ')));
		}
	}, [slug]);

	useEffect(() => {
		if (page && page?.res.data.review_status) {
			setStatus(page?.res.data.review_status);
		}
	}, [page]);

	useEffect(() => {
		if (
			currentUser.roles?.includes("superadmin") ||
			currentUser.roles?.includes("admin") ||
			currentUser.roles?.includes("ce-admin")
		) {
			setIsAdmin(true);
		}
	}, [currentUser]);

	// for directory template
	const sortSections = () => {
		let sectionArr: any = [];

		page.res.data.page_data.forEach((pageData: any) => {
			pageData.attributes.data.forEach((data: any) => {
				sectionArr.push(data);
			})
		});

		let sections: any = [];
		let sectionIndex: number = 0;

		for (let i = 0; i < sectionArr.length; i++) {
			if (sectionArr[i]['layout'] === 'section_title' && sections.length > 0) {
				sectionIndex++;
			}
			if (sections[sectionIndex] === undefined) {
				sections[sectionIndex] = {
					'title': '',
					'data': []
				};
			}
			if (sectionArr[i]['layout'] === 'section_title') {
				sections[sectionIndex]['title'] = sectionArr[i]['attributes']['section_title'];
			}
			sections[sectionIndex]['data'].push(sectionArr[i]);
		}

		if (sections.length > 0) {
			setSections(sections);
		}
	}

	// for share & scale/steal template
	const [campaigns, setCampaigns] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [maxPages, setMaxPages] = useState<number>(1);
	const [pageId, setPageId] = useState<any>('');

	const getShareAndStealCampaigns = async (pageId: any) => {
		const data: IfaceAllNewestResponse = await fetchShareAndSteal(pageId, { page: currentPage });
		if (pageId && !data.error) {
			let campaignsData: any = [...campaigns];
			campaignsData = campaignsData.concat(data.res.data);

			setCampaigns(campaignsData);

			if (data.res.meta.last_page) {
				setMaxPages(data.res.meta.last_page);
			}
		}
	};

	const loadMore = (e: any) => {
		e.preventDefault();
		setCurrentPage(currentPage + 1);
	}

	useEffect(() => {
		getShareAndStealCampaigns(pageId);
	}, [pageId, currentPage]);

	console.log('page', page);

	// Featured Card
	const [showFeaturedModal, setShowFeaturedModal] = useState<boolean>(false);

	// Featured Video URL
	const [featuredvideoUrl, setFeaturedVideoUrl] = useState<any>(null);
	useEffect(() => {
		if (page) {
			if (page?.res.data.featured_embed_url && page?.res.data.video_service) {
				setFeaturedVideoUrl(processVideoUrl(page?.res.data.featured_embed_url, page?.res.data.video_service));
			}
			if (page?.res.data.template === "directory") {
				setIsDirectory(true);
				sortSections();
			}
			if (page?.res.data.template === "share_and_steal") {
				setIsShare(true);
				setPageId(page?.res.data.id);
			}
		}
	}, [page]);

	if (fourOhFour || ((status === 'draft' || status === 'archived') && !isAdmin)) {
		return (
			<Layout title="Error 404: Page Not Found">
				<main className="page errorPage">
					<div className="wrapper">
						<h1>404</h1>
						<p>Sorry, the page you requested could not be found.</p>
						<a className="btn narrow" href="/">
							Go to Homepage
						</a>
					</div>
				</main>
			</Layout>
		)
	}

	return (
		<>
			{page ? (
				<>
					<Layout title={page.res.data.title}>
						<div className={`pageComponent ${styles.cePage} ${styles.gridPage}`}>
							{isDirectory &&
								<>
									<div className={directoryStyles.bannerImage}>
										<Image
											unoptimized={true}
											src={page.res.data.banner_image ? processBannerImage(page.res.data.banner_image, true) : '/images/null-placeholder.png'}
											alt="banner image alt text"
											layout="fill"
											objectFit="cover"
										/>
									</div>
									<div className={`${styles.wrapper} ${directoryStyles.head}`}>
										{page.res.data.title && (
											<h1>{page.res.data.title}</h1>
										)}
										{page.res.data.introduction && (
											<>{parse(page.res.data.introduction)}</>
										)}
									</div>
								</>
							}
							{isShare &&
								<div className="wrapper">
									<div className={shareScaleStyles.banner}>
										<div className={shareScaleStyles.bannerContent}>
											{page.res.data.subtitle && (
												<p className={shareScaleStyles.bannerTag}>{page.res.data.subtitle}</p>
											)}
											{page.res.data.title && (
												<h1 className={shareScaleStyles.bannerTitle}>{page.res.data.title}</h1>
											)}
											{page.res.data.introduction && (
												<>{parse(page.res.data.introduction)}</>
											)}
										</div>
										<div className={shareScaleStyles.bannerImage}>
											<Image
												unoptimized={true}
												src={page.res.data.banner_image ? processBannerImage(page.res.data.banner_image, true) : '/images/null-placeholder.png'}
												alt="banner image alt text"
												layout="fill"
												objectFit="cover"
											/>
										</div>
									</div>
									{campaigns && campaigns.length > 0 ? (
										<div className={`${styles.grid} ${styles.fourColumnGrid}`}>
											{campaigns.map((item: any) => {
												let data: any = { ...item };
												item.url = `/creative/${item.slug}`;
												item.object_type = 'campaign';
												return (
													<GridCard
														key={item.slug}
														data={item}
														title={item.title}
														thumbnail={item.banner_image ? processBannerImage(item.banner_image, true) : ''}
														url={`/creative/${item.slug}`}
														date={item.campaign_year}
														market={item.market}
														agencies={item.agencies}
														categories={item.categories}
														additionalClasses={`${styles.twoColumnCard} ${shareScaleStyles.gridCard}`}
														hasRead={item.user_viewed}
													/>
												)
											})}
										</div>
									) : (
										<p>No Campaigns found</p>
									)}
									{currentPage < maxPages && (
										<div className={styles.buttonsContainer}>
											<button className={`btn`} onClick={loadMore}>Load More</button>
										</div>
									)}
								</div>
							}
							{!isDirectory && !isShare &&
								<section id={'banner'} className={`${styles.banner}`}>
									<div className={`wrapper ${styles.wrapper}`}>
										<div className={styles.textContainer}>
											{page.res.data.title && (
												<p className={styles.subHeader}>{page.res.data.title}</p>
											)}
											{page.res.data.subtitle && (
												<h1>{page.res.data.subtitle}</h1>
											)}
											{page.res.data.introduction && (
												<>{parse(page.res.data.introduction)}</>
											)}
										</div>
									</div>
								</section>
							}
							{page.res.data.enable_featured !== 0 && page.res.data.enable_featured !== false && (
								<section className={styles.featuredContent}>
									<div className={`wrapper ${styles.wrapper}`}>
										<div className={styles.row}>
											<div className={styles.imageContainer}>
												<Image
													unoptimized={true}
													src={page.res.data.banner_image ? processBannerImage(page.res.data.banner_image, true) : '/images/null-placeholder.png'}
													alt="banner image alt text"
													layout="fill"
													objectFit="cover"
												/>
												{page.res.data.featured_embed_url && (
													<>
														<button onClick={() => {
															setShowFeaturedModal(true);
														}} className={styles.playButton}>
															<span>View Now</span>
															<IconPlay />
														</button>
														{showFeaturedModal && (
															<Modal>
																<VideoModal
																	title={page.res.data.featured_title}
																	setShowModal={setShowFeaturedModal}
																	embed={featuredvideoUrl}
																/>
															</Modal>
														)}
													</>
												)}
											</div>
											<div className={styles.textContainer}>
												<h3>{page.res.data.featured_title}</h3>
												{page.res.data.featured_description && (
													<div className={styles.excerpt}>{parse(page.res.data.featured_description)}</div>
												)}
												{page.res.data.featured_embed_url && (
													<button onClick={() => {
														setShowFeaturedModal(true);
													}} className={`${styles.playLink}`}>View Now</button>
												)}
												{page.res.data.featured_date && (
													<div className={cardStyles.metaContainer}>
														<p className={cardStyles.date}>
															<IconCalendar />
															<span>{page.res.data.featured_date.split('-')[0]}</span>
														</p>
													</div>
												)}
											</div>
										</div>
									</div>
								</section>
							)}
							{page.res.data.page_data && page.res.data.page_data.length > 0 && (
								<div>
									{page.res.data.template === "directory" && (
										<>
											{sections && sections.map((section: any, index: any) => {
												return (
													<section className={styles.contentGrid} key={`grid-section-${index}`}>
														{section.data.map((data: any, index: any) => {
															if (data.attributes.nested_data && data.attributes.nested_data.length > 0) {
																return (
																	<div className={styles.wrapper} key={`section-wrapper-${index}`}>
																		<div className={`${gsStyles.grid} ${gsStyles.row}`}>
																			<LayoutContent
																				data={data}
																			/>
																			{data.attributes.nested_data.map((item: any, index: any) => {
																				return (
																					<LayoutContent
																						key={`layout-content-${index}`}
																						data={item}
																					/>
																				)
																			})}
																		</div>
																	</div>
																)
															} else {
																return (
																	<div className={`${directoryStyles.wrapper} ${styles.wrapper} ${data.layout && data.layout === 'section_title' ? styles.sectionTitle : ''}`} key={`section-wrapper-${index}`}>
																		<LayoutContent
																			data={data}
																		/>
																	</div>
																)
															}
														})}
													</section>
												)
											})}
										</>
									)}
									{page.res.data.template === "two_column_cards" && (
										<section className={styles.librarySection}>
											<div className={`wrapper ${styles.wrapper}`}>
												<div className={`${styles.grid} ${styles.twoColumnGrid}`}>
													{page.res.data.page_data.map((card: any, index: any) => {
														const data: any = card.content.data && card.content.data.length > 0 ? { ...card.content.data[0] } : null;
														if (data) {
															let url: string = '';
															switch (card.content_type) {
																case 'briefs':
																	url += '/brief/'
																	break;
																case 'campaigns':
																	url += '/creative/'
																	break;
																case 'master_classes':
																	url += '/masterclass/'
																	break;
																default:
																	break;
															}
															url += data.slug;

															data.url = url;
															data.object_type = card.content_type;
															data.campaign_award = card.award_override ? card.award_override : null;
															data.award = card.award_override ? card.award_override : null;

															return (
																<GridCard
																	key={`grid-item-${index}`}
																	data={data}
																	title={data.title}
																	slug={data.slug}
																	url={url}
																	thumbnail={processBannerImage(data.banner_image, true)}
																	market={data.market}
																	objectType={card.content.content_type}
																/>
															)
														} else {
															return <></>
														}
													})}
												</div>
											</div>
										</section>
									)}
									{page.res.data.template === 'grid_cards' && (
										<>
											{page.res.data.page_data.map((section: any, index: any) => {
												const sectionIndex: any = index;
												return (
													<section key={`grid-section-${sectionIndex}`} className={styles.librarySection}>
														<div className={`wrapper ${styles.wrapper}`}>
															{section.section_title && (
																<h2 id={slugify(section.section_title)}>{section.section_title}</h2>
															)}
															<div className={styles.grid}>
																{section.content && section.content.length > 0 && section.content.map((card: any, index: any) => {
																	const data: any = card.content.data && card.content.data.length > 0 ? { ...card.content.data[0] } : null;
																	if (data) {
																		let url: string = '';
																		switch (card.content_type) {
																			case 'briefs':
																				url += '/brief/'
																				break;
																			case 'campaigns':
																				url += '/creative/'
																				break;
																			case 'master_classes':
																				url += '/masterclass/'
																				break;
																			default:
																				break;
																		}
																		url += data.slug;

																		data.url = url;
																		data.object_type = card.content_type;
																		data.campaign_award = card.award_override ? card.award_override : null;
																		data.award = card.award_override ? card.award_override : null;

																		return (
																			<GridCard
																				key={`grid-item-${sectionIndex}-${index}`}
																				data={data}
																				title={data.title}
																				url={url}
																				slug={data.slug}
																				thumbnail={processBannerImage(data.banner_image, true)}
																				market={data.market}
																				objectType={card.content_type}
																			/>
																		)
																	} else {
																		return <></>
																	}
																})}
															</div>
														</div>
													</section>
												)
											})}
										</>
									)}
									{(page.res.data.template === 'two_column_media' || page.res.data.template === 'grid_media') && (
										<>
											{page.res.data.page_data.map((section: any, index: any) => {
												const sectionIndex: any = index;

												return (
													<section key={`media-column-section-${sectionIndex}`} className={styles.librarySection}>
														<div className={`wrapper ${styles.wrapper}`}>
															{section.section_title && (
																<h2 id={slugify(section.section_title)}>{section.section_title}</h2>
															)}
															<div className={`${styles.grid} ${page.res.data.template === "two_column_media" ? styles.twoColumnGrid : ''}`}>
																{section.content && section.content.length > 0 && section.content.map((media: any, index: any) => {
																	let videoUrl: any = null;

																	if (media.video_id && media.video_service) {
																		videoUrl = processVideoUrl(media.video_id, media.video_service);
																	}

																	return (
																		<VideoCard
																			key={`grid-item-${sectionIndex}-${index}`}
																			title={media.title}
																			thumbnail={media.media_thumbnail}
																			embed={videoUrl}
																			description={media.featured_description}
																			additionalClasses={`${styles.mediaCard} ${styles.videoCard}`}
																		/>
																	)
																})}
															</div>
														</div>
													</section>
												)
											})}
										</>
									)}
								</div>
							)}
						</div>
					</Layout>
				</>
			) : (
				<Layout title={pageTitle}>
					<main className="page">
						<div className={`pageComponent creative-campaigns-page ${styles.gridPage}`}></div>
					</main>
				</Layout>
			)}
		</>
	)
}

export default Page;