import React, { useRef, useState, useEffect } from "react";
import VideoModal from "./VideoModal";
// import styles from "styles/pages/creative-effectiveness/CreativeCampaigns.module.scss";
import styles from "styles/components/MediaCard.module.scss";

import { createPortal } from "react-dom";

import {
    processBannerImage,
    slugify
} from "helpers/functions";
import parse from "html-react-parser";

// Next
import Image from "next/image";

// Images
import IconPlay from "images/icon-video-play.svg";

type Props = {
    title: string;
    thumbnail: string;
    embed: string;
    description?: string;
    additionalClasses?: any;
    overlay?: boolean;
    hardcoded?: boolean;
};

const Modal = (props: any) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    React.useEffect(() => {
        container.classList.add('root-protal')
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return createPortal(
        props.children,
        container,
    );
}

function VideoCard({ title, thumbnail, embed, description, additionalClasses, overlay = true, hardcoded }: Props) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className={`${styles.mediaCard} ${styles.VideoCard} ${additionalClasses && additionalClasses}`}>
                <div className={styles.mediaCardInner}>
                    <div className={styles.imageContainer}>
                        <IconPlay />
                        <button onClick={() => {
                            setShowModal(true);
                        }} className={styles.playButton}>{title}</button>
                        {thumbnail && (
                            <Image
                                unoptimized={true}
                                src={hardcoded ? thumbnail : processBannerImage(thumbnail, true)}
                                alt={'Alt Text'}
                                width={1920}
                                height={1080}
                                layout="responsive"
                                objectFit="cover"
                                objectPosition="center"
                                priority={true}
                            />
                        )}
                    </div>
                    <div className={styles.textContainer}>
                        <p className={styles.title}>{title}</p>
                        {description && (
                            <div className={styles.description}>{parse(description)}</div>
                        )}
                        <div className={styles.buttonContainer}>
                            <button onClick={() => {
                                setShowModal(true);
                            }} data-text={'View Now'} className={styles.videoModalButton}>View {title}</button>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal>
                    <VideoModal
                        title={title}
                        setShowModal={setShowModal}
                        embed={embed}
                    />
                </Modal>
            )}
        </>
    )
}

export default VideoCard;